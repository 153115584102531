<template>
	<div class="container-fluid">
		<div class="row mt-2 mb-4">
			<div class="col-sm-12 text-right">
				<h1>Notarios</h1>
			</div>
		</div>

		<div v-if="solicitudes.length > 0" class="d-flex flex-wrap card-solicitud">
			<div v-for="solicitud in solicitudes" :key="solicitud.id" class="col-md-4">
				<div class="card">
						<h4 class="card-title text-center">
							<span>Solicitud: </span>
							<span>{{ solicitud.solicitud }}</span>
						</h4>
					<div class="card-body">
							<div class="d-flex justify-content-between align-items-end list-item">
								<span class="col">Sub asignación</span>
								<span class="col text-right"> {{ validarDatos(solicitud,"subasignacion") }} </span>
							</div>

							<div class="d-flex justify-content-between align-items-end list-item">
								<span class="col">Documentación básica</span>
								<span class="col text-right"> {{ validarDatos(solicitud,"documentacion_basica") }} </span>
							</div>

							<div class="d-flex justify-content-between align-items-end list-item">
								<span class="col">Visita a valuación de inmueble</span>
								<span class="col text-right"> {{ validarDatos(solicitud,"fecha_cita") }} </span>
							</div>

							<div class="d-flex justify-content-between align-items-end list-item">
								<span class="col">Valuación</span>
								<span class="col text-right"> {{ validarDatos(solicitud,"evaluacion") }} </span>
							</div>

							<div class="d-flex justify-content-between align-items-end list-item">
								<span class="col">Solicitud de CLG</span>
								<span class="col text-right" > {{ validarDatos(solicitud,"solicitud_clg") }} </span>
							</div>

							<div class="d-flex justify-content-between align-items-end list-item">
								<span class="col">Carga de CLG</span>
								<span class="col text-right" > {{ validarDatos(solicitud,"cargar_clg") }} </span>
							</div>

							<div class="d-flex justify-content-between align-items-end list-item">
								<span class="col">Documentación completa</span>
								<span class="col text-right" > {{ validarDatos(solicitud,"documentacion_completa") }} </span>
							</div>

							<div class="d-flex justify-content-between align-items-end list-item">
								<span class="col">Firma</span>
								<span class="col text-right" > {{ validarDatos(solicitud,"firma") }} </span>
							</div>
						<br />

						<div class="">
							<router-link :to="{name: 'formalizacion_notario_solicitud', params:{solicitud: solicitud.solicitud }}" class="btn complementary-btn">Ver solicitud</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-else class="row mt-4">
			<div class="col-sm-12 text-center">No tienes solicitudes pendientes asignadas</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import api from '@/apps/formalizacion/api/v1/formalizacion'

	export default {

		data: function() {
			return {
				solicitudes: []
			}
		}
		,mounted: function() {
			this.obtenerSolicitudes();
			this.$log.info('inicio: ', Date.now());
		}

		,methods: {
			obtenerSolicitudes: async function() {
				try {
					this.solicitudes = (await api.obtenerSolicitudes(1)).data;
				}catch(e) {
					this.$log.info('exception: ',e);

					this.$helper.showAxiosError(e, 'Error');
				}
			}
			,validarDatos: function(data, col) {
				let tmpObj = new Object()

				if (data.sla && data.sla.length > 0) {
					data.sla.forEach(evento => {
						tmpObj[evento.tipo] = evento.created_at;
					});
				}

				// this.$log.info('steps', tmpObj);

				return Object.keys(tmpObj).indexOf(col) != -1 ? this.$moment(tmpObj[col]).format('YYYY-MM-DD HH:mm') : 'N/A';
			}
		}

		,watch: {
			options: {
				handler: function(val, oldVal) {
					this.obtenerSolicitudes(this.options);
				}
				,deep: true
			}
		}
	}
</script>

<style lang="scss" scope>
	.card-solicitud {
		.card-title {
			padding: 1rem;
			margin-bottom: 0;
			background-color: #056EAF;

			span {
				color: #fff !important;
				font-weight: 700;
			}
		}
	}

	.card-body {
		.list-item {
			padding: .5rem 0;
			&:nth-child(2n+1) {
				background-color: #E6E6E6;
			}

			.text-right {
				font-weight: bolder;
			}
		}
	}
</style>