import Vue from 'vue'
import axios from '@/plugins/axios'
import utils from '@/helpers/utilidades'

const baseURL = process.env.VUE_APP_API_FORMALIZACION+'/v1';

class Formalizacion
{
	obtenerSolicitudes(tipo) {
		return axios.get(baseURL+'/solicitudes/tipo/'+tipo);
	}

	detallesSolicitud(id) {
		return axios.get(baseURL+'/solicitudes/'+id);
	}

	detallesValija(solicitud, valija) {
		return axios.get(baseURL+'/solicitudes/'+solicitud+'/'+valija);
	}

	agregarObservacion(solicitud, payload) {
		return axios.post(baseURL+'/solicitudes/'+solicitud+'/observaciones', payload);
	}

	async descargar_documentos(solicitud, valija, documento, options) {
		try {
			let archivo = (await axios({url: baseURL+'/solicitudes/'+solicitud+'/'+valija+'/'+documento, method: 'GET',responseType: 'blob'})).data;

			let urlFile = window.URL.createObjectURL(new Blob([archivo], {type: options.type}));
			const link = document.createElement('a');
			link.href = urlFile;

			if (options && options.name)
				link.setAttribute('download',  options.name);
			else
				link.setAttribute('target','_blank');

			document.body.appendChild(link);

			link.click();
			link.remove();
		}catch(e) {
			if (!e.response)
				utils.showMessage('Error','Se a producido un error: '+e.message, 'error', 'alert');
			else
				utils.showAxiosError(e, 'Error');
		}
	}

	async descargar_documentos_todos(solicitud, valija, options) {
		try {
			let archivo = (await axios({url: baseURL+'/solicitudes/'+solicitud+'/'+valija+'/alldocuments',method: 'GET',responseType: 'blob'})).data;
			
			let urlFile
			urlFile = window.URL.createObjectURL(new Blob([archivo], {type: options.type}));
			const link = document.createElement('a');
			link.href = urlFile;
			link.setAttribute('download',  options.name);
			document.body.appendChild(link);

			link.click();
			link.remove();
		}catch(e) {
			if (!e.response)
				utils.showMessage('Error','Se a producido un error: '+e.message, 'error', 'alert');
			else
				utils.showAxiosError(e, 'Error');
		}
	}

	async actualizar_fase(solicitud, fase) {
		return (await axios.put(baseURL+'/solicitudes/'+solicitud+'/fase/'+fase)).data;
	}

	actualizar_oficina(solicitud, oficina) {
		return axios.put(baseURL+'/solicitudes/'+solicitud+'/oficina/'+oficina);
	}

	noviable(solicitud,payload) {
		console.log('payload', payload);
		return axios.put(baseURL+'/solicitudes/'+solicitud+'/noviable',payload);
	}

	subir_documento(solicitud, payload) {
		return axios.post(baseURL+'/solicitudes/'+solicitud+'/documento', payload);
	}

	crear_cita(solicitud, payload) {
		return axios.post(baseURL+'/solicitudes/'+solicitud+'/cita', payload);
	}

	obtener_usuarios(options) {
		return axios.get(utils.url_options(baseURL+'/usuarios', options));
	}

	obtener_empresas(options) {
		return axios.get(utils.url_options(baseURL+'/empresas', options));
	}

	crear_empresa(payload) {
		return axios.post(baseURL+'/empresas', payload);
	}

	actualizar_empresa(id, payload) {
		return axios.put(baseURL+'/empresas/'+id, payload);
	}

	obtener_empresas_oficinas(id) {
		return axios.get(baseURL+'/empresas/'+id+'/oficinas');
	}

	obtener_oficina(id) {
		return axios.get(baseURL+'/empresas/oficina/'+id);
	}

	crear_oficina(id, payload) {
		return axios.post(baseURL+'/empresas/'+id+'/oficinas', payload);
	}

	eliminar_oficina(id, oficina) {
		return axios.delete(baseURL+'/empresas/'+id+'/oficinas/'+oficina);
	}

	crear_usuario(payload) {
		return axios.post(baseURL+'/usuarios', payload);
	}

	actualizar_usuario(id, payload) {
		return axios.put(baseURL+'/usuarios/'+id, payload);
	}

	eliminar_usuario(id) {
		return axios.delete(baseURL+'/usuarios/'+id);
	}

	mi_usuario() {
		return axios.get(baseURL+'/usuarios/mi-usuario');
	}

	mis_eventos() {
		return axios.get(baseURL+'/usuarios/eventos');
	}

	obtener_documentos() {
		return axios.get(baseURL+'/otros/documentos');
	}

	reenviar_correo(id) {
		return axios.get(baseURL+'/usuarios/'+id+'/reenviaremail');
	}
}

export default new Formalizacion();